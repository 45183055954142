<template>
  <div id="successToast" :class="{ 'ar-popup': isArabicLocale }">
    <img
      width="20"
      height="20"
      src="../../assets/toasts/success.webp"
      alt=""
      class="mr-2"
    />

    <div>
      <p :class="{ 'px-2': isArabicLocale }" class="m-0" id="successText">
        {{ $t("toast.Submitted successfully!") }}
      </p>
    </div>
  </div>
</template>

<script>
import arabicLocale from "@/mixins/arabicLocale.js";

export default {
  mixins: [arabicLocale],
  name: "SuccessToast",
};
</script>

<style scoped>
#successToast {
  visibility: hidden;
  min-width: 250px;
  margin-left: -125px;
  background: #ebf7ee;
  border: 1px solid #45a041;
  color: #333c48;
  border-radius: 10px;
  padding: 16px;
  position: fixed;
  z-index: 999999999999999;
  left: 50%;
  top: 30px;
  font-size: 16px;
  display: flex;
  align-items: center;
}

#successToast.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
  from {
    top: 0;
    opacity: 0;
  }
  to {
    top: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    top: 0;
    opacity: 0;
  }
  to {
    top: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    top: 30px;
    opacity: 1;
  }
  to {
    top: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    top: 30px;
    opacity: 1;
  }
  to {
    top: 0;
    opacity: 0;
  }
}
</style>
